export const CUSTOM_DATE_FORMATS = {
    parse: {
        dateInput: 'DD. MMM YYYY',
    },
    display: {
        dateInput: 'DD. MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

export const ADS_DATE_FORMAT = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'DD. MMM YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
}

export const MESSAGE_DATE_FORMAT = {
    parse: {
        dateInput: 'DD.MM.YYYY HH:mm'
    },
    display: {
        dateInput: 'DD.MM.YYYY HH:mm',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY'
    }
};

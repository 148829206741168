import { Inject, Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {LogService} from '../services/utility/log.service';

@Injectable()
export abstract class RestServiceAbstract {

    constructor(private http: HttpClient,
                @Inject('API_URL') private API_URL: string,
                @Inject(LogService) public log: LogService) {
    }

    /**
     * @param {string} relativeUrl
     * @param {any} params
     */
    protected get(relativeUrl: string, params: any = {}): Observable<Object> {
        return this.http.get(this.API_URL + relativeUrl, params);
    }

    /**
     * @param {string} relativeUrl
     * @param {Object} data
     * @return {Observable<Object>}
     */
    protected post(relativeUrl: string, data: Object): Observable<Object>  {
        return this.http.post(this.API_URL + relativeUrl, data);
    }

    protected patch(relativeUrl: string, data: Object): Observable<Object>  {
        return this.http.patch(this.API_URL + relativeUrl, data);
    }

    protected put(relativeUrl: string, data: Object): Observable<Object>  {
        return this.http.put(this.API_URL + relativeUrl, data);
    }

    protected delete(relativeUrl: string, data?: Object): Observable<Object>  {
        return this.http.delete(this.API_URL + relativeUrl, data);
    }
}
